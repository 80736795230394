
.auto-refresh-modal :global(.ant-modal-content), .auto-refresh-modal :global(.ant-modal-content .ant-modal-header){
 border-radius: 8px;
}
.auto-refresh-modal :global(.ant-modal-content .ant-modal-close .ant-modal-close-icon) {
  vertical-align: 0;
}
.auto-refresh-modal :global(.ant-modal-content .ant-modal-title) {
  font-size: 18px;
}
.auto-refresh-modal :global(.ant-modal-content .ant-modal-body) {
  font-size: 16px;
}

.auto-refresh-modal :global(.ant-modal-content .ant-modal-footer) button{
  border-radius: 8px;
}