@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.eot');
    src: url('Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype'),
        url('Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.eot');
    src: url('Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff'),
        url('Poppins-SemiBold.ttf') format('truetype'),
        url('Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Medium.eot');
    src: url('Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Medium.woff2') format('woff2'),
        url('Poppins-Medium.woff') format('woff'),
        url('Poppins-Medium.ttf') format('truetype'),
        url('Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.eot');
    src: url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype'),
        url('Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Thin.eot');
    src: url('Poppins-Thin.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Thin.woff2') format('woff2'),
        url('Poppins-Thin.woff') format('woff'),
        url('Poppins-Thin.ttf') format('truetype'),
        url('Poppins-Thin.svg#Poppins-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'General Sans';
    src: url('GeneralSans-Bold.eot');
    src: url('GeneralSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('GeneralSans-Bold.woff2') format('woff2'),
        url('GeneralSans-Bold.woff') format('woff'),
        url('GeneralSans-Bold.ttf') format('truetype'),
        url('GeneralSans-Bold.svg#GeneralSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OoohBaby';
    src: url('OoohBaby-Regular.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'BroadBay';
    src: url('BroadbayNormal-JJv7.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

